import React from "react";
import { concatClassNames as cn } from "@system42/core";
import { HighlightedText } from "../../system42";

import * as styles from "./styles.module.css";

export function GetStartedBoxPress(props) {
  const { className, title, titleWidth, textBottom } = props;

  const theTitle = title || (
    <>
      You´re a journalist & want to{" "}
      <HighlightedText>pick our Userbrains?</HighlightedText>
    </>
  );
  const theTitleWidth = title ? titleWidth : "13em";

  return (
    <section className={cn(className, styles.getStartedBox)}>
      <div>
        <h1 style={{ maxWidth: theTitleWidth }}>{theTitle}</h1>
      </div>
      <span className={styles.getStartedBox_content}>
        Send a press inquiry to{" "}
        <a href={"mailto:press@userbrain.net"}>press@userbrain.net</a>
      </span>
      {textBottom && <div className={styles.textBottom}>{textBottom}</div>}
    </section>
  );
}
