// extracted by mini-css-extract-plugin
export var column = "styles-module--column--acbd1";
export var column1 = "styles-module--column1--91cad";
export var column2 = "styles-module--column2--ae759";
export var column3 = "styles-module--column3--fa375";
export var columnHeading = "styles-module--columnHeading--4e00f";
export var column_component = "styles-module--column_component--e9092";
export var column_componentContainer = "styles-module--column_componentContainer--4f2e7";
export var columns = "styles-module--columns--43685";
export var link = "styles-module--link--4fb72";
export var subtitle = "styles-module--subtitle--d56f4";
export var text = "styles-module--text--faf42";
export var title = "styles-module--title--5dee5";