import React from "react";
import { concatClassNames as cn } from "@system42/core";
import Layout from "../../layouts/layout";
import {
  Headline1Serif,
  Headline1Sans,
  Headline2Sans,
  Overline,
  Button,
} from "@simplease/system42-theme-userbrain";
import { HeaderTitle } from "../../components/HeaderTitle";
import { ThreeColumnsCards } from "../../components/ThreeColumnsCards";
import { WavySeparator } from "../../components/WavySeparator";
import { ListCentered } from "../../components/ListCentered";
import { TwoColumns } from "../../components/TwoColumns";
import { ResourceDownloadTeaserGrid } from "../../components/ResourceDownloadTeaserGrid";
import { ThreeColumnsSquircles } from "../../components/ThreeColumnsSquircles";
import { GetStartedBoxPress } from "../../components/GetStartedBoxPress";

import imgTesterStats from "./tester-stats.png";
import imgLogoKitIcon from "./logo-kit-resource-icon.svg";
import imgPressKitIcon from "./press-kit-resource-icon.svg";
import imgBootstrappedByIcon from "../../images/lineIcons/bootstrapped-by.svg";
import imgUserbitBlogLogo from "./userbit-blog-logo.svg";
import imgMonitoLogo from "./monito-logo.svg";
import imgDerStandardLogo from "./der-standard-logo.svg";

import * as helperStyles from "../helpers.module.css";
import * as styles from "./styles.module.css";

import { advertedPoolSize } from "../../helpers";

export default function PressHub() {
  return (
    <Layout>
      <HeaderTitle>
        <Headline1Serif>Userbrain Press Hub</Headline1Serif>
      </HeaderTitle>

      <div className={helperStyles.containerColor_noPaddingMobile}>
        <ThreeColumnsCards
          className={styles.newsCards}
          overline={"Userbrain in the news"}
          card1TopComponent={
            <img
              src={imgUserbitBlogLogo}
              alt={"UserBit Blog Logo"}
              className={styles.newsCards_logo}
            />
          }
          card1Title={
            "Top 5 online services to recruit user research participants in 2021"
          }
          card1Subtext={"January 11, 2021"}
          card1Href={
            "https://userbit.com/content/blog/top-services-for-research-recruitment-2021"
          }
          card2TopComponent={
            <img
              src={imgMonitoLogo}
              alt={"Monito Logo"}
              className={styles.newsCards_logo}
            />
          }
          card2Title={"The digital toolbox that will set you apart"}
          card2Subtext={"January 15, 2019"}
          card2Href={
            "https://medium.com/monito-stories/the-digital-toolbox-that-will-set-you-appart-7c32dc5e392d"
          }
          card3TopComponent={
            <img
              src={imgDerStandardLogo}
              alt={"Der Standard Logo"}
              className={styles.newsCards_logo}
            />
          }
          card3Title={
            "Userbrain: Grazer User-Testing-Start-up erhält sechsstellige Förderung"
          }
          card3Subtext={"July 9, 2018"}
          card3Href={
            "https://www.derstandard.de/story/2000082978829/userbrain-grazer-user-testing-start-up-erhaelt-sechsstellige-foerderung"
          }
          below={<Button>View all</Button>}
        />
      </div>

      <div className={cn(helperStyles.containerColor, styles.pressContact)}>
        <Overline>Press contact</Overline>
        <span className={styles.pressContact_name}>Markus Pirker</span>
        <a
          className={styles.pressContact_email}
          href={"mailto:press@userbrain.net"}
        >
          press@userbrain.net
        </a>
      </div>

      <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

      <div className={helperStyles.container}>
        <Headline2Sans
          className={cn(helperStyles.spacingLg, styles.pressReleasesTitle)}
        >
          Press releases:
        </Headline2Sans>
        <ListCentered
          className={cn(helperStyles.spacingMd, styles.pressReleasesList)}
          color={"#FFF4F0"}
          items={[
            {
              title:
                "Userbrain: 6-digit FFG Funding for the User Testing Startup from Graz",
              text: "18. Nov. 2020",
              linkHref: "./",
            },
            {
              title: "Userbrain releases support for Australian testers",
              text: "18. Nov. 2020",
              linkHref: "./",
            },
            {
              title: "Userbrain releases support for Australian testers",
              text: "18. Nov. 2020",
              linkHref: "./",
            },
          ]}
        />

        <TwoColumns
          className={helperStyles.spacingLg}
          imgSrc={imgTesterStats}
          imgAlt={"65k+ Userbrain Testers"}
          heading={"Userbrain in a nutshell"}
          content={
            <>
              <p>
                The user testing tool was created by Markus Pirker, Mathias
                Placho, Stefan Rössler, and Andreas Riedmüller – a team of UX
                experts and designers who have been consulting big-brand
                clients, with their company Simplease, for over a decade. It
                quickly became apparent that there was a need in the market for
                a tool that can carry out fast, simple, and affordable user
                tests with real people. So they built it.{" "}
              </p>
              <p>
                With Userbrain, industry giants like Audi, Spotify, Red Bull,
                and Virgin can tap into {advertedPoolSize} quality assured
                testers and build products with exceptional user experience.
              </p>
            </>
          }
        />
      </div>

      <ResourceDownloadTeaserGrid
        className={cn(
          helperStyles.containerColor,
          helperStyles.spacingLg,
          styles.pressResources
        )}
        headline={"Userbrain press resources:"}
        items={[
          {
            title: "Logo kit",
            text: "Download the official Userbrain logo in every size and color to pop it into your media outlet of choice.",
            imgSrc: imgLogoKitIcon,
            imgAlt: "Userbrain Logo Kit",
          },
          {
            title: "Press kit",
            text: "Download the most important info you need to write a story about Userbrain straight to your desktop.",
            imgSrc: imgPressKitIcon,
            imgAlt: "Userbrain Press Kit",
          },
        ]}
      />

      <div className={helperStyles.container}>
        <ThreeColumnsSquircles
          className={cn(helperStyles.container, helperStyles.spacingLg)}
          title={"Userbrain stats:"}
          col1Component={
            <img src={imgBootstrappedByIcon} alt={"Bootrapped Icon"} />
          }
          col1Text={"Bootstrapped by four founders from day one."}
          col2Component={<Headline1Sans>{advertedPoolSize}</Headline1Sans>}
          col2Text={`Backed by a pool of ${advertedPoolSize} quality assured user testers.`}
          col3Component={<Headline1Sans>323k</Headline1Sans>}
          col3Text={`Racked up over 323k minutes of recorded user tests over the years.`}
        />

        <GetStartedBoxPress className={cn(helperStyles.spacingLg)} />
      </div>
    </Layout>
  );
}
