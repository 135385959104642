// extracted by mini-css-extract-plugin
export var belowCards = "styles-module--belowCards--a5d0a";
export var cardSubtext = "styles-module--cardSubtext--54716";
export var cardTitle = "styles-module--cardTitle--8ca10";
export var column = "styles-module--column--075f7";
export var columnCard = "styles-module--columnCard--528ca";
export var columnCard1 = "styles-module--columnCard1--67e8c";
export var columnCard2 = "styles-module--columnCard2--72e3e";
export var columnCard3 = "styles-module--columnCard3--23c04";
export var columnCard_content = "styles-module--columnCard_content--d4a16";
export var columnCard_content_top = "styles-module--columnCard_content_top--851a9";
export var columnCardsContainer = "styles-module--columnCardsContainer--88349";
export var columns = "styles-module--columns--8e96a";
export var overlineText = "styles-module--overlineText--83f9c";
export var threeColumnsCards = "styles-module--threeColumnsCards--7194b";